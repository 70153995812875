@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(./fonts/Gilroy-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-Regular';
  src: local('Gilroy-Regular'),
    url(./fonts/Gilroy-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url(./fonts/Gilroy-Light.otf) format('opentype');
}
@font-face {
  font-family: 'Gilroy-Bold';
  font-weight: 600;
  src: local('Gilroy-Bold'), url(./fonts/Gilroy-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-ExtraBold';
  font-weight: 900;
  src: local('Gilroy-ExtraBold'),
    url(./fonts/Gilroy-ExtraBold.otf) format('opentype');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
