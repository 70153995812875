.header {
  font-weight: 600;
  color: #17356b;
  line-height: 2rem;
}

.divider {
  background-color: #5f8bfe;
  height: 1px;
  width: 100%;
}
